<template>
  <div class="container mt-5">
    <h3
      data-aos-duration="2000"
      data-aos="fade-right"
      class="mb-4 moving-gradient-text fw-bolder"
    >
      SEND US A SMOKE SIGNAL
    </h3>
    <p class="mb-4">
      Have a question or an awesome idea or just fancy a bit of a geeky
      chin-wag? Drop us a message and a member of the team will holla back as
      soon as possible!
    </p>
    <!-- <form @submit.prevent="formSubmit" v-if="!sent">
      <div class="row">
        <div class="col-12 col-md-6 mb-4">
          <input
            type="text"
            class="form-control custom-input"
            placeholder="Full name"
            v-model="form.first_name"
            required
          />
        </div>
        <div class="col-12 col-md-6 mb-4">
          <input
            type="text"
            class="form-control custom-input"
            placeholder="Last name"
            v-model="form.last_name"
            required
          />
        </div>
      </div>

      <div class="row">
        <div class="col-12 col-md-6 mb-4">
          <input
            type="email"
            class="form-control custom-input"
            placeholder="Email address"
            v-model="form.email"
            required
          />
        </div>
        <div class="col-12 col-md-6 mb-4">
          <input
            type="number"
            class="form-control custom-input"
            placeholder="Phone number"
            v-model="form.phone"
            required
          />
        </div>
      </div>

      <div class="row mb-4">
        <div class="col-12">
          <textarea
            rows="5"
            class="form-control custom-input"
            placeholder="Ahoy there..."
            v-model="form.input"
            required
          ></textarea>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <vue-recaptcha
            ref="recaptcha"
            :sitekey="recaptchaSiteKey"
            :loadRecaptchaScript="true"
            @verify="recaptchaOnVerify"
            @expired="recaptchaOnExpire"
            size="invisible"
          />
          <button
            type="submit"
            class="btn btn-outline-dark black-button btn-lg"
          >
            SEND
          </button>
        </div>
      </div>
    </form> -->
    <crank-crm-contact-form
      :api-key="'93459181-717c-4e37-a0d6-026d8ffe76c4'"
      :btn-color="'black'"
      :btn-text-color="'white'"
      :btn-border="'none'"
      :form-bg-color="'transparent'"
      :form-text-color="'#333'"
      :form-border="'2px solid black'"
      :border-radius="'0'"
      :thank-you-message="'Thanks for your message, a member of the team will be in touch soon...'"
    ></crank-crm-contact-form>
    <!-- <div class="row" v-else>
      <div class="col">
        <h4 class="fw-bold">
          Thanks for your message, a member of the team will be in touch soon...
        </h4>
      </div>
    </div> -->
  </div>
</template>

<script>
import VueRecaptcha from "vue-recaptcha";
export default {
  data() {
    return {
      sent: false,
      form: {
        first_name: "",
        last_name: "",
        email: "",
        phone: "",
        input: "",
      },
    };
  },
  computed: {
    recaptchaSiteKey() {
      return process.env.VUE_APP_G_RECAPTCHA_V2_SITE_KEY;
    },
  },
  methods: {
    formSubmit() {
      this.$refs.recaptcha.execute();
    },
    recaptchaOnVerify() {
      this.sendSmokeSignal();
    },
    recaptchaOnExpire() {
      this.$refs.recaptcha.reset();
    },
    sendSmokeSignal() {
      this.$axios
        .post(process.env.VUE_APP_API_URL + "/f/form-captures", this.form)
        .then(({ data }) => {
          console.log(data);
          this.sent = true;
          this.form = {
            first_name: "",
            last_name: "",
            email: "",
            phone: "",
            input: "",
          };

          setTimeout(() => {
            this.$router.push("/");
          }, 5000);
        });
    },
  },
  components: {
    VueRecaptcha,
  },
};
</script>

<style>
.custom-input,
.black-button {
  border-radius: 0;
  border-width: 2px;
  border-color: #000;
}
</style>
